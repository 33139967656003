import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Briefcase, Users, Zap, Heart } from 'lucide-react';

const CareerPage = () => {
  const benefits = [
    { icon: Briefcase, title: 'Flexible Work', description: 'Balance your work and personal life with our flexible schedules and remote work options.' },
    { icon: Users, title: 'Collaborative Environment', description: 'Join a team that values open communication and collective problem-solving.' },
    { icon: Zap, title: 'Continuous Learning', description: 'Stay at the forefront of tech with our learning programs and conference attendances.' },
    { icon: Heart, title: 'Health & Wellness', description: 'Comprehensive health benefits and wellness programs to keep you at your best.' },
  ];

  return (
    <div className="py-12 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <motion.h2 
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-3xl font-extrabold text-gray-900 sm:text-4xl"
          >
            Join Our Innovation Journey
          </motion.h2>
          <p className="mt-4 text-xl text-gray-600">
            Shaping the future of technology, one line of code at a time.
          </p>
        </div>

        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
          className="mt-16"
        >
          <h3 className="text-2xl font-bold text-gray-900 mb-8">Why Research & Analytics Solutions?</h3>
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
            {benefits.map((benefit, index) => (
              <motion.div 
                key={index}
                whileHover={{ scale: 1.05 }}
                className="bg-white rounded-lg shadow-lg p-6"
              >
                <benefit.icon className="h-12 w-12 text-blue-500 mb-4" />
                <h4 className="text-xl font-semibold mb-2">{benefit.title}</h4>
                <p className="text-gray-600">{benefit.description}</p>
              </motion.div>
            ))}
          </div>
        </motion.div>

        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          className="mt-16 bg-blue-600 rounded-lg shadow-xl overflow-hidden"
        >
          <div className="px-6 py-12 sm:px-12 sm:py-16 lg:flex lg:items-center lg:justify-between">
            <div>
              <h3 className="text-3xl font-extrabold text-white sm:text-4xl">
                Ready to make an impact?
              </h3>
              <p className="mt-3 max-w-3xl text-lg text-blue-200">
                While we don't have any current openings, we're always on the lookout for exceptional talent. Drop us your resume, and we'll keep you in mind for future opportunities!
              </p>
            </div>
            <div className="mt-8 lg:mt-0 lg:flex-shrink-0">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="/contact"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50"
                >
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </motion.div>

        <div className="mt-16">
          <h3 className="text-2xl font-bold text-gray-900 mb-8">Our Culture</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <motion.div 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.6, duration: 0.5 }}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <img className="w-full h-64 object-cover" src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="Team collaboration" />
              <div className="p-6">
                <h4 className="text-xl font-semibold mb-2">Innovate Together</h4>
                <p className="text-gray-600">At Research & Analytics Solutions, we believe in the power of collaboration. Our open office spaces and regular brainstorming sessions foster creativity and innovation.</p>
              </div>
            </motion.div>
            <motion.div 
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.8, duration: 0.5 }}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <img className="w-full h-64 object-cover" src="https://images.unsplash.com/photo-1542744094-3a31f272c490?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="Work-life balance" />
              <div className="p-6">
                <h4 className="text-xl font-semibold mb-2">Work-Life Harmony</h4>
                <p className="text-gray-600">We understand the importance of balance. Flexible working hours, remote work options, and our focus on mental health contribute to a happier, more productive team.</p>
              </div>
            </motion.div>
          </div>
        </div>

        <motion.div 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1, duration: 0.5 }}
          className="mt-16"
        >
          <img 
            className="w-full h-96 object-cover rounded-lg shadow-xl" 
            src="https://images.unsplash.com/photo-1531482615713-2afd69097998?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" 
            alt="Research & Analytics Solutions team" 
          />
        </motion.div>
      </div>
    </div>
  );
};

export default CareerPage;