import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Code, Server, Users, Shield, Database, Cloud } from 'lucide-react';

const ServiceCard = ({ icon: Icon, title, description, features }) => (
  <motion.div 
    className="bg-white p-6 rounded-lg shadow-lg"
    whileHover={{ scale: 1.03 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <Icon className="w-12 h-12 text-blue-500 mb-4" />
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600 mb-4">{description}</p>
    <ul className="list-disc list-inside text-gray-600">
      {features.map((feature, index) => (
        <li key={index}>{feature}</li>
      ))}
    </ul>
  </motion.div>
);

const ServicesPage = () => {
  const services = [
    {
      icon: Code,
      title: "Custom Software Development",
      description: "Tailored solutions to meet your unique business needs.",
      features: [
        "Web and mobile app development",
        "Enterprise software solutions",
        "Legacy system modernization",
        "API development and integration"
      ]
    },
    {
      icon: Server,
      title: "IT Infrastructure Management",
      description: "Optimize and secure your IT environment for peak performance.",
      features: [
        "Network design and implementation",
        "Server management and virtualization",
        "24/7 monitoring and support",
        "Disaster recovery planning"
      ]
    },
    {
      icon: Users,
      title: "IT Consultancy",
      description: "Expert advice to guide your technology decisions.",
      features: [
        "IT strategy development",
        "Technology roadmap planning",
        "Vendor selection assistance",
        "IT audit and compliance"
      ]
    },
    {
      icon: Shield,
      title: "Cybersecurity Solutions",
      description: "Protect your digital assets from evolving threats.",
      features: [
        "Security assessment and planning",
        "Firewall and intrusion detection systems",
        "Employee security awareness training",
        "Incident response and forensics"
      ]
    },
    {
      icon: Database,
      title: "Data Management & Analytics",
      description: "Harness the power of your data for informed decision-making.",
      features: [
        "Database design and optimization",
        "Big data processing and analytics",
        "Business intelligence solutions",
        "Data visualization and reporting"
      ]
    },
    {
      icon: Cloud,
      title: "Cloud Services",
      description: "Leverage the cloud for scalability and flexibility.",
      features: [
        "Cloud migration strategies",
        "Multi-cloud and hybrid cloud solutions",
        "Cloud-native application development",
        "Cloud security and compliance"
      ]
    }
  ];

  return (
    <div className="py-12 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Our Comprehensive IT Services
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Empowering your business with cutting-edge technology solutions
          </p>
        </div>

        <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {services.map((service, index) => (
            <ServiceCard key={index} {...service} />
          ))}
        </div>

        <div className="mt-16 text-center">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">
            Not sure what you need?
          </h3>
          <p className="text-xl text-gray-600 mb-8">
            Our experts are here to help you find the perfect solution for your business.
          </p>
          <Link
            to="/contact"
            className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
          >
            Schedule a Consultation
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ServicesPage;