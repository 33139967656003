import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { CheckCircle, TrendingUp, Users, Award } from 'lucide-react';

const AboutPage = () => {
  const teamMembers = [
    { name: "Rahul Gurujala", role: "CEO & Founder", image: "https://media.licdn.com/dms/image/D4D03AQGkX6XjEe_9CQ/profile-displayphoto-shrink_800_800/0/1721461336251?e=1726704000&v=beta&t=mN5Nn6o6dkQf7w0aJ8v42KW0LB0fUw4eLcExOUhx8IQ" },
    { name: "Ashish Kushwah", role: "CTO", image: "https://media.licdn.com/dms/image/C5103AQEf4T_EbXKX-g/profile-displayphoto-shrink_800_800/0/1563267615338?e=1726704000&v=beta&t=uyVcO0mMv2RWkIsrB4mWFviHBMlD7CkIQhupFjRxuOM" },
    { name: "Govind Phule", role: "UX Designer", image: "https://media.licdn.com/dms/image/C5603AQHU8wT0c6LzTg/profile-displayphoto-shrink_800_800/0/1609403609432?e=1726704000&v=beta&t=92UY-8-gNnIwZvquGBEmHMJdrQwJvGEjBYzLbwztBM4" },
    { name: "Rahul Gupta", role: "Lead Developer", image: "https://media.licdn.com/dms/image/D4D03AQGIny3oEVmlPw/profile-displayphoto-shrink_800_800/0/1697217285987?e=1726704000&v=beta&t=4XlcoIx0HKndFVfQzC17u-v0XxqM1oczEB-2TdhisjU" },
  ];

  return (
    <div className="py-12 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            About Research & Analytics Solutions
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Pioneering IT solutions for a digital future
          </p>
        </div>

        <div className="mt-16">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">Our Story</h3>
          <p className="text-lg text-gray-600 mb-6">
            Founded in 2018, Research & Analytics Solutions emerged from a vision to bridge the gap between complex technology and business needs. Our journey began with a small team of passionate tech enthusiasts, and has now grown into a full-service IT solutions provider trusted by businesses worldwide.
          </p>
          <p className="text-lg text-gray-600 mb-6">
            At Research & Analytics Solutions, we believe in the power of technology to transform businesses and improve lives. Our mission is to deliver innovative, scalable, and secure IT solutions that drive our clients' success in the digital age.
          </p>
        </div>

        <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          <motion.div whileHover={{ scale: 1.05 }} className="text-center">
            <CheckCircle className="w-12 h-12 text-blue-500 mx-auto mb-4" />
            <h4 className="text-lg font-semibold text-gray-900">100+ Projects Completed</h4>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} className="text-center">
            <TrendingUp className="w-12 h-12 text-blue-500 mx-auto mb-4" />
            <h4 className="text-lg font-semibold text-gray-900">95% Client Satisfaction</h4>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} className="text-center">
            <Users className="w-12 h-12 text-blue-500 mx-auto mb-4" />
            <h4 className="text-lg font-semibold text-gray-900">50+ Expert Team Members</h4>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} className="text-center">
            <Award className="w-12 h-12 text-blue-500 mx-auto mb-4" />
            <h4 className="text-lg font-semibold text-gray-900">10 Industry Awards</h4>
          </motion.div>
        </div>

        <div className="mt-16">
          <h3 className="text-2xl font-bold text-gray-900 mb-4">Our Team</h3>
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
            {teamMembers.map((member, index) => (
              <motion.div 
                key={index} 
                whileHover={{ scale: 1.05 }}
                className="text-center"
              >
                <img className="w-32 h-32 rounded-full mx-auto mb-4" src={member.image} alt={member.name} />
                <h4 className="text-lg font-semibold text-gray-900">{member.name}</h4>
                <p className="text-gray-600">{member.role}</p>
              </motion.div>
            ))}
          </div>
        </div>

        <motion.div 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
          className="mt-16 bg-blue-600 rounded-lg shadow-xl overflow-hidden"
        >
          <div className="px-6 py-12 sm:px-12 sm:py-16 lg:flex lg:items-center lg:justify-between">
            <div>
              <h3 className="text-3xl font-extrabold text-white sm:text-4xl">
                Join Our Team
              </h3>
              <p className="mt-3 max-w-3xl text-lg text-blue-200">
                We're always looking for talented individuals to join our innovative team. Check out our career opportunities and be part of shaping the future of technology.
              </p>
            </div>
            <div className="mt-8 lg:mt-0 lg:flex-shrink-0">
              <div className="inline-flex rounded-md shadow">
                <Link
                  to="/careers"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-blue-600 bg-white hover:bg-blue-50"
                >
                  View Careers
                </Link>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default AboutPage;